import $ from 'jquery';
const ELEMENT_SELECTOR = '.js__send-request-on-click';
export default class SendRequestOnClick {
    static init($root = $('body')) {
        const $element = $root.find(ELEMENT_SELECTOR);
        if (!$element.length) {
            return;
        }
        this.trackClicks($element);
    }
    static trackClicks($element) {
        $element.on('click', e => {
            const $element = $(e.currentTarget);
            const { requestUrl, requestData } = $element.data();
            const blob = new Blob([JSON.stringify(requestData)], { type: 'application/json' });
            navigator.sendBeacon(requestUrl, blob);
        });
    }
}
